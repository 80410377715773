import * as Sentry from '@sentry/nextjs'
import { Partner } from '@src/types/strapi/collections/partners'
import { api } from './api'

export const PARTNERS_ENDPOINT = '/parceiros/uf'

export type PartnersRequest = {
  uf: string
  page: number
  limit?: string
}

export type PartnersResponse = {
  partners: Partner[]
  page: number
  pageSize: number
  totalPages: number
  totalPartners: number
}

export const getPartners = async (request: PartnersRequest): Promise<PartnersResponse> => {
  try {
    const { uf, page, limit } = request
    const { data } = await api.get<PartnersResponse>(`${PARTNERS_ENDPOINT}/${uf}`, { params: { page, limit } })
    return data
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}
