import Content from '@components/Content'
import TextWithLink from '@components/TextWithLink'
import TitleWithIcon from '@components/TitleWithIcon'
import useWindowSize from '@src/hooks/useWindowSize'
import Image from 'next/image'
import { Fragment, useMemo } from 'react'
import styles from './FeesAndDeadline.module.scss'

const FeesAndDeadline = ({ columns }) => {
  const { isMobile } = useWindowSize()

  const renderTable = ({ title, lines, description, id }) => {
    return (
      <Fragment key={id}>
        <div className={styles.tableContainer}>
          <div className={styles.tableTitle}>{title}</div>
          <table>
            {lines.map(line => (
              <tr key={line.id}>
                <td>{line.left_text}</td>
                <td>{line.right_text}</td>
              </tr>
            ))}
          </table>
        </div>
        {Boolean(description) && <p className={styles.tableDescription}>{description}</p>}
      </Fragment>
    )
  }

  const renderColumn = ({ id, title, description, tables, icon, blue_card }) => {
    const displayText = useMemo(
      () => (
        <TextWithLink
          content={blue_card?.description}
          link={blue_card?.link}
          classNameLink={styles.link}
        ></TextWithLink>
      ),
      [blue_card?.description]
    )

    return (
      <div key={id}>
        <TitleWithIcon title={title} iconUrl={icon?.url} hideIcon={isMobile || !icon} />

        <div className={styles.tables}>{tables.map(renderTable)}</div>

        {Boolean(blue_card) && (
          <div className={styles.blueCard}>
            <div className={styles.cardIcon}>
              <Image layout="fixed" src={blue_card.icon?.url} height={46} width={46} alt="Imagem" />
            </div>

            <div>
              <div className={styles.cardTitle}>{blue_card.title}</div>
              <div className={styles.cardDescription}>{displayText}</div>
            </div>
          </div>
        )}

        <p className={styles.columnDescription}>{description}</p>
      </div>
    )
  }

  return (
    <div>
      <Content>
        <div className={styles.grid}>{columns.map(renderColumn)}</div>
      </Content>
    </div>
  )
}

export default FeesAndDeadline
