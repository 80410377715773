import ButtonStrapi from '@components/ButtonStrapi'
import Content from '@components/Content'
import Markdown from '@components/Markdown'
import { IModalityList } from '@components/Sections/ModalityList/ModalityList.interface'
import cn from 'classnames'
import Image from 'next/image'
import React, { useCallback } from 'react'
import styles from './ModalityList.module.scss'

const ModalityList: React.FC<IModalityList> = ({ title, items }) => {
  const renderItem = useCallback(
    ({ id, title: itemTitle, description, button, image, heading, flipHorizontal }) => {
      return (
        <div key={id} className={cn(styles.item, { [styles.itemReverse]: flipHorizontal })}>
          <div className={styles.imageContainer}>
            <Image
              layout="responsive"
              src={image.url}
              width={image.width}
              height={image.height}
              quality={90}
              alt="Imagem"
            />
          </div>
          <div className={styles.itemContent}>
            <span className={styles.heading}>{heading}</span>
            {itemTitle && <h1 className={styles.itemTitle}>{itemTitle}</h1>}
            <div className={styles.description}>
              <Markdown>{description}</Markdown>
            </div>
            {button && <ButtonStrapi {...button} />}
          </div>
        </div>
      )
    },
    [items]
  )

  return (
    <div>
      <Content className={styles.content}>
        {title && <h1 className={styles.title}>{title}</h1>}
        <div className={styles.itemsContainer}>{items.map(renderItem)}</div>
      </Content>
    </div>
  )
}

export default ModalityList
