import Content from '@components/Content'
import ListFrame from '@components/ListFrame'
import { IListItems } from '@components/ListFrame/ListFrame.interface'
import SimulatorCard from '@components/SimulatorCard'
import { IImage, IImages } from '@src/interfaces/Home.interface'
import { api } from '@src/service/api'
import Image from 'next/image'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { ILoanSimulator } from './LoanSimulator.interface'
import styles from './LoanSimulator.module.scss'

const LoanSimulator: FC<ILoanSimulator> = loanProps => {
  const [amounts, setAmounts] = useState([])

  const imagemWithDefault = (image: IImage, imgDefault: string | 'undefined') => (image ? image?.url : imgDefault)

  const renderImageFrame: FC<IImages> = images => {
    return (
      <div className={styles.images}>
        <div>
          <div className={styles.icon1}>
            <Image src={imagemWithDefault(images[2], '/satisfaction.svg')} width={37} height={37} alt="Imagem" />
          </div>
          <div className={styles.icon2}>
            <Image src={imagemWithDefault(images[1], '/dollar.svg')} width={37} height={37} alt="Imagem" />
          </div>
        </div>
        <div className={styles.imagens}>
          <Image src={imagemWithDefault(images[0], '/')} width={471.4} height={423} alt="Imagem" />
        </div>
      </div>
    )
  }

  const renderInfo: FC<IListItems> = props => {
    return (
      <div className={styles.info}>
        <p>{props.title}</p>
        <ListFrame {...props} noCentralizeResponsive />
      </div>
    )
  }

  const renderList: FC<IListItems> = props => {
    return (
      <div className={styles.list}>
        <p>{props.title}</p>
        <ListFrame {...props} noCentralizeResponsive />
      </div>
    )
  }

  const requestSimulation = useCallback(
    async (value, deadline, type) => {
      const { agency, company, daysToPayment, product, store, plan, shopkeeper } = loanProps

      const simulateByAmount = type === 'amount'

      const payload = {
        company,
        agency,
        product,
        shopkeeper,
        store,
        plan,
        deadline,
        buyValue: simulateByAmount ? value : undefined,
        pmtValue: simulateByAmount ? undefined : value,
        daysToPayment,
      }

      const { data } = await api.post('/pages/simulation', payload)
      return data
    },
    [loanProps]
  )

  const renderSimulator = ({ cards }) => {
    return (
      <div className={styles.frameSimulator}>
        <p>{cards.title}</p>
        <SimulatorCard {...cards} amounts={amounts} onSimulate={requestSimulation} />
      </div>
    )
  }

  const BackgroundDotted = () => (
    <div className={styles.backgroundImage}>
      <img src="/background.svg" />
    </div>
  )

  const requestDeadlines = useCallback(async () => {
    const { plan } = loanProps
    const { data } = await api.get(`/pages/simulation/${plan}`)
    const values = data.map(item => ({ key: item.Prazo, label: `${parseInt(item.Prazo, 10)}x`, tax: item.Taxa }))
    setAmounts(values)
  }, [loanProps])

  useEffect(() => {
    requestDeadlines()
  }, [])

  return (
    <Content className={styles.content}>
      <div>
        <BackgroundDotted />
        <div className={styles.frame}>
          <div>
            {renderImageFrame(loanProps.images)}
            {loanProps.type === 'items' && loanProps.items && renderList(loanProps.items)}
            {loanProps.type === 'info' && loanProps.info && renderInfo(loanProps.info)}
          </div>
          {renderSimulator(loanProps)}
        </div>
        <BackgroundDotted />
      </div>
    </Content>
  )
}

export default memo(LoanSimulator)
