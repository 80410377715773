import { Box, IconButton, Text } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { ArrowLeftIcon, ArrowRightIcon } from '@src/icons'
import { StrapiPartnersCarousel } from '@src/types/strapi/components/partners-carousel'
import { carouselDelayInMs } from '@src/utils/delay'
import Image from 'next/image'
import { useRef } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './PartnersCarousel.module.scss'

export const PartnersCarousel = ({ partnersImages, timeout, title }: StrapiPartnersCarousel) => {
  const swiperRef = useRef<any>(null)
  const hasMoreItems = partnersImages.length > 4

  return (
    <Container marginY="3rem">
      <Box width="100%" position="relative">
        <Text
          fontSize={{ sm: '1.5rem', md: '2rem' }}
          fontWeight={700}
          textAlign="center"
          marginBottom="3.125rem"
          color="gray.700"
        >
          {title}
        </Text>
        <Box height="2.5rem">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={60}
            breakpoints={{
              320: {
                slidesPerView: 2,
              },
              480: {
                slidesPerView: 3,
              },
              640: {
                slidesPerView: 4,
              },
            }}
            className={styles.customCarousel}
            onBeforeInit={swiper => {
              swiperRef.current = swiper
            }}
            autoplay={{
              enabled: hasMoreItems,
              delay: carouselDelayInMs(timeout),
            }}
            pagination={{
              clickable: true,
              enabled: true,
              bulletClass: `swiper-pagination-bullet ${styles.customBullet}`,
              bulletActiveClass: `swiper-pagination-bullet-active ${styles.customBulletActive}`,
            }}
          >
            {partnersImages.map(item => {
              return (
                <SwiperSlide key={item.id} className={styles.customCarouselItem}>
                  <Image src={item.url} layout="fill" alt="Imagem" />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Box>
        {hasMoreItems && (
          <>
            <IconButton
              borderColor="blue.500"
              icon={<ArrowLeftIcon />}
              variant="outline"
              display={{ base: 'none', md: 'initial' }}
              aria-label="prev-button"
              position="absolute"
              top="67%"
              backgroundColor="white"
              borderRadius="100%"
              zIndex={2}
              width="3rem"
              height="3rem"
              onClick={() => swiperRef.current?.slidePrev()}
            />
            <IconButton
              borderColor="blue.500"
              icon={<ArrowRightIcon />}
              display={{ base: 'none', md: 'initial' }}
              variant="outline"
              aria-label="next-button"
              backgroundColor="white"
              position="absolute"
              top="67%"
              right={0}
              zIndex={2}
              borderRadius="100%"
              width="3rem"
              height="3rem"
              onClick={() => swiperRef.current?.slideNext()}
            />
          </>
        )}
      </Box>
    </Container>
  )
}
