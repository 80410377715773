import Alert, { IAlert } from '@components/Alert'
import Content from '@components/Content'
import StrapiForm from '@components/StrapiForm'
import { IStrapiForm } from '@components/StrapiForm/StrapiForm.interface'
import * as Sentry from '@sentry/nextjs'
import { api } from '@src/service/api'
import { Upload } from 'interfaces/Home.interface'
import Image from 'next/image'
import { useCallback, useState } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import styles from './CreditOpeningForm.module.scss'

interface IProps {
  form: IStrapiForm
  title: string
  contact_label: string
  phone: string
  subtitle: string
  image: Upload
}

const CreditOpeningForm: React.FC<IProps> = ({ form: strapiForm, title, contact_label, phone, subtitle, image }) => {
  const [alert, setAlert] = useState<IAlert>({})
  const [loading, setLoading] = useState<boolean>(false)
  const { isMobile } = useWindowSize()

  const onSubmit = useCallback(
    async (formData: any, form: any) => {
      const submitData = strapiForm.inputs.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.name]: formData[curr.name],
        }),
        {}
      )

      try {
        setLoading(true)
        await api.post(`/pages/form/${strapiForm.id}`, submitData)
        form.reset({ faturamento: '' })
        setAlert({
          status: 'success',
          message: strapiForm.success_message,
        })
      } catch (ex) {
        Sentry.captureException(ex)
        setAlert({
          status: 'error',
          message: strapiForm.error_message,
        })
      } finally {
        setLoading(false)
      }
    },
    [strapiForm]
  )

  return (
    <Content>
      <div className={styles.container}>
        <div className={styles.image}>
          <Image src={image.url} width={image.width} height={image.height} quality={85} alt="Imagem" />
          <div className={styles.phoneContainer}>
            <h1>{contact_label}</h1>
            <div className={styles.phone}>
              <img src="/phone.svg" width={23} height={23} />
              <h1>{phone}</h1>
            </div>
          </div>
        </div>
        <div className={styles.form}>
          <img className={styles.formDotsTop} src="/dots.svg" />
          <div className={styles.formContent}>
            {alert.status ? <Alert {...alert}></Alert> : null}
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <StrapiForm
              inputs={strapiForm.inputs}
              buttonFullWidth={isMobile}
              submitText={strapiForm.submit_text}
              loading={loading}
              onSubmit={onSubmit}
            />
          </div>
          <img className={styles.formDotsBottom} src="/dots.svg" />
        </div>
      </div>
    </Content>
  )
}

export default CreditOpeningForm
