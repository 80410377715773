import Image from 'next/image'
import styles from './TitleWithIcon.module.scss'

interface ITableWithIconProps {
  iconUrl: string
  title: string
  width?: number
  height?: number
  hideIcon?: boolean
}

const TitleWithIcon: React.FC<ITableWithIconProps> = ({ iconUrl, title, width, height, hideIcon }) => {
  return (
    <div className={styles.itemTitle}>
      {!hideIcon && <Image src={iconUrl} width={width || 24} height={height || 24} alt="Imagem" />}
      <p>{title}</p>
    </div>
  )
}

export default TitleWithIcon
