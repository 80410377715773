import Image from 'next/image'
import useWindowSize from '../../../hooks/useWindowSize'
import styles from './Cover.module.scss'

const Cover = ({ heading, title, photo, description }) => {
  const { isMobile } = useWindowSize()

  const renderContent = () => {
    return (
      <div className={styles.content}>
        <div>
          <div className={styles.textContainer}>
            <h3>{heading}</h3>
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
      </div>
    )
  }

  const renderContentMobile = () => {
    return (
      <div className={styles.contentMobile}>
        <h3>{heading}</h3>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className={styles.coverMobile}>
        <div className={styles.imageContainerMobile}>
          <Image layout="intrinsic" src={photo.url} width={photo.width} height={photo.height} alt="Imagem" />
        </div>
        {(heading || title || description) && renderContentMobile()}
      </div>
    )
  }

  return (
    <div className={styles.cover}>
      {(heading || title || description) && renderContent()}
      <div className={styles.imageContainer}>
        <Image width={1240} height={571} src={photo.url} objectFit="fill" objectPosition="center" alt="Imagem" />
      </div>
    </div>
  )
}

export default Cover
