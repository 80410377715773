import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { StrapiContact } from '@src/types/strapi'
import Image from 'next/image'

const ContactItem = ({ item }: { item: StrapiContact['contactItem'][number] }) => {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      borderRadius={'0.5rem'}
      borderWidth={'1px'}
      borderColor={'blue.50'}
      width={'full'}
      paddingX={{ base: '0.8rem', md: '1.5rem' }}
      paddingY={{ base: '0.5rem', md: '1rem' }}
      justifyContent={'space-between'}
      gridGap={{ base: '0.5rem', md: '0' }}
    >
      <HStack gridGap={{ base: '0.5rem', md: '1rem' }}>
        <Image src={item.icon.url} alt={item.icon.name} width={29} height={29} />
        <Text fontSize={'1.25rem'} color={'gray.600'}>
          {item.label}
        </Text>
      </HStack>
      <Text fontSize={'1.25rem'} color={'gray.500'}>
        {item.value}
      </Text>
    </Flex>
  )
}

export const ContactList = (props: { items: StrapiContact['contactItem'] }) => {
  return (
    <VStack gridGap={'0.5rem'} width={'full'} alignItems={'flex-start'}>
      {props.items.map(item => (
        <ContactItem item={item} key={item.id} />
      ))}
    </VStack>
  )
}
