import Content from '@components/Content'
import { IDescriptionWithIcon } from '@src/interfaces/Home.interface'
import Image from 'next/image'
import { FC } from 'react'
import styles from './DescriptionWithIcon.module.scss'

const DescriptionWithIcon: FC<IDescriptionWithIcon> = ({ title, subTitle, description, titleCards, cards }) => {
  const renderCard = ({ icon, text, id }) => {
    return (
      <div key={id} className={styles.card}>
        <div>{Boolean(icon) && <Image src={icon.url} width={90} height={90} alt="Imagem" />}</div>
        <p>{text}</p>
      </div>
    )
  }

  return (
    <Content className={styles.content}>
      <div className={styles.condition}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subTitle}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.titleCards}>{titleCards}</div>
        <div className={styles.cardsContainer}>{cards.map(renderCard)}</div>
      </div>
    </Content>
  )
}

export default DescriptionWithIcon
