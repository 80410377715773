import Content from '@components/Content'
import useWindowSize from '@src/hooks/useWindowSize'
import { ICardNumber, INumberPresentation, Upload } from '@src/interfaces/Home.interface'
import Image from 'next/image'
import { FC } from 'react'
import styles from './NumbersPresentation.module.scss'

const RenderCard: FC<any> = ({ className, cards, index }) => {
  if (cards.length < index + 1) return null

  const { title, description } = cards[index]

  return (
    <div className={className}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  )
}
const RenderIconCard: FC<Upload & { className: any }> = ({ url, className }) => (
  <div className={className}>
    <Image src={url} width={38} height={38} alt="Imagem" />
  </div>
)

const NumbersPresentation: FC<INumberPresentation> = ({ title, description, numberCards, icon1, icon2 }) => {
  const { isDesktop } = useWindowSize()

  const renderDesktop: FC<ICardNumber[]> = cards => (
    <div className={styles.frameImage}>
      <RenderIconCard className={styles.icon1} {...icon1} />
      <RenderCard className={styles.card3} cards={cards} index={2} />
      <RenderCard className={styles.card4} cards={cards} index={3} />
      <RenderCard className={styles.card1} cards={cards} index={0} />
      <RenderCard className={styles.card2} cards={cards} index={1} />
      <RenderIconCard className={styles.icon2} {...icon2} />
    </div>
  )
  const renderMobile: FC<ICardNumber[]> = cards => (
    <div className={styles.frameImage}>
      <RenderIconCard className={styles.icon1} {...icon1} />
      <RenderCard className={styles.card3} cards={cards} index={2} />
      <RenderCard className={styles.card1} cards={cards} index={0} />
      <RenderCard className={styles.card2} cards={cards} index={1} />
      <RenderCard className={styles.card4} cards={cards} index={3} />
      <RenderIconCard className={styles.icon2} {...icon2} />
    </div>
  )
  return (
    <Content>
      <div className={styles.frame}>
        <div className={styles.frametext}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        {isDesktop ? renderDesktop(numberCards) : renderMobile(numberCards)}
      </div>
    </Content>
  )
}

export default NumbersPresentation
