import { Flex, Heading, Text, VStack } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { StrapiInformativeBlock } from '@src/types/strapi/components/informative-block'
import Image from 'next/image'

const InformativeBlock = ({ informativeBlockImage, title, subTitle, flip, color }: StrapiInformativeBlock) => {
  return (
    <Container>
      <Flex
        width="100%"
        alignItems="center"
        flexDirection={{ base: 'column', lg: flip ? 'row-reverse' : 'row' }}
        marginX={{ lg: '20px', xl: '0' }}
      >
        <Image
          src={informativeBlockImage.url}
          width={483}
          height={273}
          style={{ borderRadius: '0.5rem' }}
          alt="Imagem"
        />
        <VStack
          backgroundColor={color === 'blue' ? 'blue.50' : 'orange.100'}
          borderTopRightRadius={{ base: 'none', lg: '0.5rem' }}
          borderBottomRightRadius={{ base: 'none', lg: '0.5rem' }}
          maxWidth="55.25rem"
          paddingX={{ base: '1.25rem', xl: '3.5313rem' }}
          paddingY={{ base: '2.5rem', xl: '3.75rem' }}
        >
          <Heading
            as="h2"
            fontWeight={700}
            fontSize={{ base: '1rem', lg: '1.4rem', xl: '1.625rem' }}
            textAlign="center"
            color="gray.700"
          >
            {title}
          </Heading>
          <Text
            fontWeight={400}
            fontSize={{ base: '0.8rem', lg: '18.4px', xl: '1.25rem' }}
            textAlign="center"
            color="gray.700"
          >
            {subTitle}
          </Text>
        </VStack>
      </Flex>
    </Container>
  )
}

export default InformativeBlock
